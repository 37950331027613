@font-face {
  font-family: 'CreatoDisplay-Light';
  src: url('./static/font/CreatoDisplay-Light.otf');
}
@font-face {
  font-family: 'CreatoDisplay-Regular ';
  src: url('./static/font/CreatoDisplay-Regular.otf');
}

@font-face {
  font-family: 'CreatoDisplay-Medium';
  src: url('./static/font/CreatoDisplay-Medium.otf');
}
@font-face {
  font-family: 'CreatoDisplay-Bold';
  src: url('./static/font/CreatoDisplay-Bold.otf');
}

@font-face {
  font-family: 'NotoSansKR-Light';
  src: url('./static/font/NotoSansKR-Light.otf');
}

/* html {} */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.carousel-next:before {
  background-image: url("data:image/svg+xml,%3C!-- Generated by IcoMoon.io --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Earrow-right2%3C/title%3E%3Cpath fill='%23ccc' d='M19.414 27.414l10-10c0.781-0.781 0.781-2.047 0-2.828l-10-10c-0.781-0.781-2.047-0.781-2.828 0s-0.781 2.047 0 2.828l6.586 6.586h-19.172c-1.105 0-2 0.895-2 2s0.895 2 2 2h19.172l-6.586 6.586c-0.39 0.39-0.586 0.902-0.586 1.414s0.195 1.024 0.586 1.414c0.781 0.781 2.047 0.781 2.828 0z'%3E%3C/path%3E%3C/svg%3E%0A") !important;
}
.carousel-prev:before {
  background-image :url("data:image/svg+xml,%3C!-- Generated by IcoMoon.io --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Earrow-left2%3C/title%3E%3Cpath fill='%23ccc' d='M12.586 27.414l-10-10c-0.781-0.781-0.781-2.047 0-2.828l10-10c0.781-0.781 2.047-0.781 2.828 0s0.781 2.047 0 2.828l-6.586 6.586h19.172c1.105 0 2 0.895 2 2s-0.895 2-2 2h-19.172l6.586 6.586c0.39 0.39 0.586 0.902 0.586 1.414s-0.195 1.024-0.586 1.414c-0.781 0.781-2.047 0.781-2.828 0z'%3E%3C/path%3E%3C/svg%3E%0A") !important;
}