// 
html {
  overflow-x: hidden;
  // overscroll-behavior-y: contain;
}

body {
  overscroll-behavior-y: contain;
  overflow-x: hidden;
  font-family: 'Creato Display Regular', 'Creato Display Regular',
    'Apple SD Gothic Regular', 'Apple SD Gothic', 'Creato display', sans-serif !important;
}

div.root {
  // overscroll-behavior-y: none;
}

/* ANDERSONC  Molecules */

// .ItemCard {
//   .itemLabels {
//     padding: 0.5rem 0;
//     height: 2.5rem;
//     display: flex;
//     img {
//       height: 1.5rem;
//       margin-right: 0.5rem;
//     }

//   }
// }

.appDiv {
  background-color: white;
}

.topSnack {
  .MuiSnackbar-root {
    z-index: 99999;
  }
}

.topSnackSimpleDialogRoot {
  .MuiPaper-root {
    max-width: 30rem;
    font-size: 1rem;
  }

  &.m .MuiPaper-root {
    width: 74.47vw;
    // height: 47.87vw;
  }

  &.pc .MuiPaper-root {
    width: 70%;
    height: 15rem;
  }

  .MuiDialogContent-root {
    width: 100%;
    height: 100%;
  }

  &.m .MuiDialogContent-root {
    padding: 0;
  }

  &.m .content {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 14.63vw 1rem 7.98vw 1rem;
  }

  &.pc .content {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .title {
    font-weight: bold;
  }
}

// .header

.header {
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  z-index: 1100;

  .cartBadge span.MuiBadge-badge {
    background-color: #ffde3b;
  }

  .topContainer {
    border-bottom: 2px solid #dcdcdc;
    align-items: center;
    display: flex;
    place-content: space-around;
    height: 60px;

    @media only screen and (max-width: 900px) {
      .menuBtn {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .cartBtn {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      padding-left: 0;
      padding-right: 0;
    }

    @media only screen and (min-width: 900px) {
      padding: 0px 24px;
    }

    img.logo {
      width: 180px;

      @media only screen and (max-width: 900px) {
        width: 140px;
      }
    }
  }
}

// .mobileTopBanner
.mobileTopBanner {
  height: 48px;
  padding: 15px 20px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

// .headerNavBarM
.headerNavBarM {
  display: flex;
  justify-content: space-between;
  padding: 6.65vw 3.55vw;
  height: 18.61vw;

  .leftArea {
    display: flex;
    justify-content: space-between;
    width: 12.41vw;
  }

  .rightArea {
    display: flex;
    justify-content: space-between;
    width: 12.41vw;

    >.cartIcon {
      >.MuiBadge-root>.MuiBadge-badge {
        position: absolute;
        top: -0.886vw;
        right: -0.886vw;
        border-radius: 1.773vw;
        height: 3.55vw;
        width: 3.55vw;
        min-width: 3.55vw;
        font-size: 2vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  img.icon {
    width: 4.25vw;
    height: 4.25vw;
  }

  img.logo {
    width: 29.7vw;
    height: 4.25vw;
  }
}

// .sideMenu
.sideMenu {
  >.backScreening {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f0f0f0;
    opacity: 0.5;
  }

  &.m>.mSideMenu {
    width: 77.92vw;
    left: -77.92vw;
  }

  // MenuOpen
  >.mSideMenu.menuOpen {
    left: 0;
  }

  &.m>.mSideMenu.menuOpen {
    box-shadow: 0px 0px 5px 2px #ddd;
  }

  >.mSideMenu {
    position: fixed;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f0f0f0;
    transition: left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      boxShadow 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    width: 400px;
    left: -400px;
  }

  >.mSideMenu .mHalfMid {
    display: flex;
    overflow: auto;
    flex-direction: column;
    flex-grow: 1;
  }

  >.mSideMenu .mHalfMid .mMenuItems {
    cursor: pointer;
    margin-bottom: 30px;
    padding-top: 50px;

    >._bottom_btn {
      display: flex;
      justify-content: flex-start;
      background-color: #f2f2f2;
      padding: 0 20px;

      &.m {
        padding: 0 4.08vw;
      }

      >._btn_inner {
        width: 100%;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 60px;
      }

      &.m>._btn_inner {
        height: 11.08vw;
      }
    }
  }

  &.m>.mSideMenu .mHalfMid .mMenuItems {
    padding-top: 0px;
  }

  &.m>.mSideMenu .mHalfMid .mMenuItems.otherServices {
    margin-top: 0px;
  }

  .mSideMenu .mHalfMid .mMenuItems .mMenuItem {
    color: 555555;
    font-weight: bold;
    padding: 17.5px 45px;
  }

  &.m .mSideMenu .mHalfMid .mMenuItems .mMenuItem {
    padding: 10px 17px;
  }

  .mSideMenu .mHalfMid .mMenuItems .mMenuItem .mMenuItem_name {
    display: flex;
    align-items: end;

    &.opened {
      color: '#585858';
    }
  }

  .mSideMenu .mHalfBottom {
    flex: 0 0 20px;
    text-align: center;

    a {
      color: '#707070';
    }

    .compayName {
      color: #707070;
      padding: 10px;
      margin-bottom: 20px;
      font-size: 16px;
    }
  }

  .m .mSideMenu .mHalfBottom .compayName {
    font-size: 11px;
  }

  .mSideMenu>.sideMenuHeader {
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;

    &.m {
      padding: 5.58vw;
      height: 17.2vw;
    }

    >div>img._home_icon {
      width: 28px;
    }

    &.m>div>img._home_icon {
      width: 4.96vw;
    }

    >div>img._adsc_logo {
      width: 168px;
    }

    &.m>div>img._adsc_logo {
      width: 29.7vw;
    }
  }

  .loginUserInfo {
    display: flex;
    flex-direction: column;
    background-color: white;

    >._name_box {
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    >._btn_box {
      display: flex;
      padding: 0.5rem;
      height: 50px;

      >._logoff_btn {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      >._mypage_btn {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid lightgray;
      }
    }
  }

  .logoffUserInfo {
    border-bottom: 1px solid white;
    display: flex;
    height: 56px;

    &.m {
      min-height: 13vw;
      max-height: 13vw;
      height: 13vw;
    }

    >._login_btn {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffd608;
    }

    >._join_btn {
      flex: 1;
      border-left: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffd608;
    }
  }

  .reservationArea {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffd608;
    height: 54px;

    >._reservation_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;

      &.m {
        height: 14.8vw;
      }
    }
  }
}

// .sideMenuTab
.sideMenuTab.m {
  background-color: white;

  >.tab {
    display: flex;

    >.tabItem {
      flex: 1;
      background-color: #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.5rem;
      font-size: 14px;

      &.selected {
        background-color: white;
      }
    }
  }

  >.tabBody {
    margin-top: 4.12vw;
    margin-bottom: 3.55vw;
  }
}

.sideMenuTab.pc {
  background-color: white;

  >.tab {
    display: flex;

    >.tabItem {
      flex: 1;
      background-color: #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.5rem;
      font-size: 1rem;

      &.selected {
        background-color: white;
      }
    }
  }
}

.sideMenuTab .menuItem1.m {
  margin: 1.24vw 4.7vw;

  >div.ndiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.82vw 0;
    font-weight: bold;
    font-size: 3.55vw;

    &.bn {
      font-size: 4.88vw;
    }

    >div.ndiv_btn {
      color: #4d4d4d;
      font-size: 2.66vw;
    }
  }

  >div.cdiv {
    padding-bottom: 1rem;
  }
}

.sideMenuTab .menuItem1.pc {
  margin: 1.3rem;

  >div.ndiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    font-weight: bold;
    font-size: 1.3rem;

    >div.ndiv_btn {
      color: #4d4d4d;
      font-size: 1rem;
    }
  }

  >div.cdiv {
    padding-bottom: 1rem;
  }
}

.sideMenuTab .menuItem2.m {
  margin: 1.55vw 0;
  font-size: 3.55vw;
  color: #808080;
}

.sideMenuTab .menuItem2.pc {
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #808080;
}

// .footer
.footer {
  a {
    color: gray;
    text-decoration: none;
  }

  .footerWeb {
    color: gray;
    margin-top: 10rem;
    font-size: 0.7rem;
    padding: 10px;

    .row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 20px;

      .col {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.zipCodeSearchDialogContent {
  &.MuiDialogContent-root {
    padding: 0.7rem;
  }

  >._head {
    border: 1px solid lightgray;
    display: flex;
    padding: 0;
    flex-direction: row;

    >._dh {
      background-color: #eeeeee;
      padding: 20px 10px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    >._db {
      padding: 10px;
      width: 100%;

      >._db_form {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
      }

      >._db_desc {
        font-size: 0.7rem;
      }
    }
  }

  &.m>._head {
    flex-direction: column;
  }

  >._body {
    height: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    flex: 0 0 20px;
  }

  span.clickable {
    text-decoration: underline;
  }

  .AddrItem {
    margin: 1rem;
  }

  .tabItem {
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    background-color: lightgray;
    border-bottom: 1px solid gray;
    margin-bottom: 20px;

    &.selected {
      background-color: white;
      border-top: 1px solid gray;
      border-left: 1px solid gray;
      border-right: 1px solid gray;
      border-bottom: none;
    }
  }
}


// ItemList - designer searching top header
.top-designer-header-div {
  padding: 30px 30px 0px 30px;
  width: 75%;
  max-width: 1280px;

  &.mobile {
    width: 100%;
  }

  >.designer-col-name {
    display: flex;
    justify-content: flex-start;
    margin-top: 0rem;
    margin-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    font-family: 'Creato Display', sans-serif;
    font-size: 12px;
    font-weight: 200;
    color: #8a8d91;
  }

  >.designer-col-value {
    display: flex;
    justify-content: flex-start;
    margin-top: 0rem;
    margin-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Creato Display', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    color: #1d1d1b;
  }
}

// ItemList - TopCategoryM
.top-category-m-div {
  .all-button {
    height: 36px;
    margin-bottom: 2%;
    padding: 4px 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Apple SD Gothic Regular', 'Apple SD Gothic', sans-serif;
    font-size: 1rem;
    border: solid #f2f2f2 1px;

    &.selected {
      background-color: #f2f2f2;
    }
  }

  .top-category-list-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .cat-box {
      text-align: center;
      line-height: 0.9rem;
      height: 36px;
      width: 23.5%;
      margin-bottom: 2%;
      padding: 4px 0;
      border: solid #f2f2f2 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Apple SD Gothic Regular', 'Apple SD Gothic', sans-serif;
      font-size: 0.75rem;
      color: #7f8080;

      &.selected {
        background-color: #f2f2f2;
      }

      &.blank {
        border: none;
      }
    }
  }

  .category-title {
    height: 18px;
    margin: 0.75rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Apple SD Gothic Bold', 'Apple SD Gothic', sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
  }

  .sub-category-list-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 12px;

    .cat-box {
      height: 12px;
      width: 20%;
      margin: 0.25rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Creato Display Regular', 'Creato Display Regular',
        'Apple SD Gothic Regular', 'Apple SD Gothic', 'Creato display',
        sans-serif;
      font-size: 0.625rem;
      color: #7f8080;

      &.selected {
        font-family: 'Creato Display Bold', 'Creato Display Bold',
          'Apple SD Gothic Regular', 'Apple SD Gothic', 'Creato display',
          sans-serif;
        font-weight: bold;
      }
    }
  }
}

// .itemListView
.itemListView {
  &.pc {
    padding-top: 30px;
  }

  .MuiContainer-root {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  >._itemList {
    width: 100%;

    .NoOutline_Select.MuiInputBase-root {
      font-size: 0.7rem;
    }

    .listArea {
      padding-top: 10px;
    }

    @media only screen and (min-width: 600px) {
      width: 75%;
      max-width: 1280px;
    }

    ._pagination {
      .MuiPaginationItem-root {
        border-radius: 5px;
        font-family: Arial;
        font-size: 1rem;
        margin: 0 1px;
        min-width: 30px;
        width: 30px;

        &.Mui-selected {
          background-color: #f8d84b;
          color: white;
        }
      }
    }
  }
}

// ItemList - list-count-opt-div
.list-count-opt-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  height: 16px;
}

.orderingDiv {
  display: flex;
  align-items: center;
  height: 16px;
  justify-content: space-evenly;
}

.resultSpan {
  color: #7f7f7f;
  font-family: 'Creato Display Bold', 'Creato display', sans-serif;
  font-weight: bold;
  font-size: 0.625rem;
}

.orderingSpan {
  color: #7f7f7f;
  font-family: 'Creato Display Light', 'Creato display', sans-serif;
  font-size: 0.625rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  padding: 4px 0;
  cursor: pointer;
  white-space: nowrap;

  &.bold {
    font-family: 'Creato Display Bold', 'Creato display', sans-serif;
    font-weight: bold;
  }
}

// rectCardCarousel
.rectCardCarousel {
  width: 100%;
  padding-top: 66.6%;
  position: relative;

  >.slick-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;

    >.slick-list {
      height: 100%;

      >.slick-track {
        height: 100%;
        display: flex;
        align-items: center;

        >.slick-slide div {
          height: 100%;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    >.slick-dots {
      pointer-events: none;
      position: unset;
      width: 100%;
      padding-top: 15px;
      display: flex;
      justify-content: space-around;

      li {
        color: black;
        width: 14px;
      }

      li button:before {
        pointer-events: all;
        content: '○';
        font-size: 14px;
      }

      li.slick-active button:before {
        content: '●'; // color: lightgray, color: black, opacity: 1,
      }

      .imageDotArea {
        >.slick-slider>.slick-list>.slick-track>.slick-slide>div {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }

        width: 100%;
        height: 100%;

        .imageDotDiv {
          pointer-events: all;
          width: 100px;
          display: inline-block;
          margin-left: 5px;
          max-width: 128px;
          max-height: 96px;

          img {
            width: -webkit-fill-available;
            height: 100%;
          }

          &.slick-active {
            img: {
              border: 2px solid lightgray;
            }
          }
        }
      }
    }
  }

  .customArrowDiv {
    &.mouseout {
      display: none;
    }

    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 1000;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    .pnArea {
      pointer-events: all;
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: 'rgb(255,255,255,0.4)';
    }

    .prevArea {
      display: flex;
      width: 10%;
      height: 20%;
      align-items: center;
      justify-content: center;
    }

    .nextArea {
      display: flex;
      width: 10%;
      height: 20%;
      align-items: center;
      justify-content: center;
    }
  }
}

// ItemList - item-card
.item-card {
  text-decoration: none;
  color: black;
  margin-top: 0.5rem;
  padding-left: 1rem;

  .image-box {
    position: relative;
    margin-bottom: 0.625rem;

    .labelArea {
      position: absolute;
      z-index: 999;
      display: flex;
    }

    .labelicon {
      height: 24px;
      padding: 6px;
      margin-top: 4px;
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 19px;
      }
    }
  }

  .designer-box {
    height: 2rem;
    margin: 0.125rem 0 0.5rem 0;
    padding: 0.125rem 0;
    display: flex;
    align-items: flex-start;
    font-family: 'Creato Display Bold', 'Creato display', sans-serif;
    font-weight: bold;
    font-size: 0.75rem;
    color: #000000;
  }

  .itemname-box {
    height: 1.5rem;
    padding: 0.125rem 0;
    margin: 0.125rem 0;
    display: flex;
    align-items: center;
    font-family: 'Creato Display Regular', 'Creato display', sans-serif;
    font-size: 0.75rem;
    color: #000000;
  }

  .price-box {
    margin-top: 0.375rem;

    .discount-box {
      height: 10px;

      margin-bottom: 0.25rem;
      padding: 0.125rem 0;
      display: flex;
      align-items: center;

      .before-price {
        font-size: 0.5rem;
        color: #7f8080;
        text-decoration: line-through;
        text-decoration-color: #f1aa25;
      }

      .rate {
        margin-left: 0.5rem;
        font-size: 0.5rem;

        color: #f1aa25;
      }
    }

    .saleprice-box {
      margin-top: 0.125rem;
      padding: 0.125rem 0;
      height: 12px;
      display: flex;
      align-items: center;
      font-family: 'Apple SD Gothic REgular', 'Apple SD Gothic', sans-serif;

      .ask {
        font-size: 0.75rem;

        @media only screen and (min-width: 600px) {
          font-size: 1rem;
        }
      }

      .price {
        font-size: 0.75rem;
      }
    }
  }

  .soldout-box {
    // margin: 0.125rem 0;
    margin-top: 0.625rem;
    line-height: 0;

    img {
      width: 100%;
    }
  }
}

// MyPageM
.myPageM {
  .margin_bar {
    height: 1rem;
    background-color: #f0f0f0;
  }

  span.username {
    font-size: 2rem;
    font-weight: bold;
  }

  span.username_nim {
    margin-left: 1rem;
    font-size: 1.3rem;
  }

  .boxpm {
    padding: 1.5rem;

    &.flexbtwn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.8rem;
      font-weight: bold;

      >.flexRowCenter {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        >img {
          width: 14vw;
        }
      }
    }
  }
}

// myPageLayout
.myPageLayout {
  max-width: 1200px;
  margin: auto;

  >.myPageLayout_header {
    background-color: #f2f2f2;
    margin-bottom: 50px;
    padding: 50px 40px;

    >.a1 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 40px;
    }

    >.a2 {
      font-size: 28px;

      >.a_name {
        font-size: 40px;
        font-weight: bold;
        margin-right: 20px;
      }

      >.a_info {
        cursor: pointer;
        margin-left: 10px;
        font-size: 16px;
        text-decoration: underline;
      }
    }
  }

  >.myPageLayout_main {
    display: flex;

    >.myPageLayout_leftMenu {
      flex: 1;
      max-width: 200px;
      margin-right: 60px;

      >.myPageLayout_leftMenu_body {
        border: 1px solid black;

        .selected {
          background-color: #FBB117;
        }

        .h1 {
          height: 60px;
          min-width: 198px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 1rem;
          font-size: 18px;
          font-weight: bold;

          >img {
            width: 36px;
            margin-right: 8px;
          }
        }

        .h2 {
          height: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 1rem;
        }

        /* bottom line */
        >.bl {
          border-bottom: 1px solid black;
        }

        /* background gray */
        >.bg {
          background-color: #f2f2f2;
        }

        .bw {
          background-color: white;
        }

        .hide {
          overflow: hidden;
          height: 0;
        }

        >.bold {
          font-weight: bold;
        }

        >.ck {
          cursor: pointer;
        }
      }
    }

    >.myPageLayout_rightBody {
      flex: 2;
    }
  }
}

// MyServiceList
.myServiceList {
  width: 100%;

  >.myServiceList_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >img {
      width: 60px;
      margin-bottom: 16px;
    }

    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  >.myServiceList_cond_type {
    border: 2px solid #d9d9d9;
    font-size: 20px;

    >div {
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        border-bottom: 2px solid #d9d9d9;
        padding: 5px 0;
      }

      >div {
        cursor: pointer;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 2px solid #d9d9d9;
        padding: 5px 0;

        &:first-child {
          border-left: none;
        }
      }
    }

    .selected {
      background-color: #FBB117;
      font-weight: bold;
    }
  }

  >div>.myServiceList_cond_period {
    display: flex;
    align-items: center;
    width: 50%;

    >img {
      width: 20px;
      margin: 1.5rem 2rem;
    }

    >div {
      cursor: pointer;
      flex: 1;
      // width: 96px;
      white-space: nowrap;
      font-size: 0.9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      border-width: 1px 0px 1px 1px;
      border-color: #d9d9d9;
      border-style: solid;

      &:last-child {
        border-width: 1px 1px 1px 1px;
      }
    }
  }

  >.myServiceList_body {
    >._list {
      width: 100%;

      >._list_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        padding: 0.2rem 0;
        background-color: #e6e6e6;
      }

      >._list_body {
        >._list_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.6rem 0;
          border-bottom: 1px solid lightgray;
        }
      }

      ._col {
        flex: 1;
        display: flex;
        justify-content: center;

        &.c1 {
          flex: 1;
          min-width: 115px;
        }

        &.c2 {
          flex: 1;
          min-width: 90px;
        }

        &.c3 {
          flex: 1;
          min-width: 100px;
        }

        &.c4 {
          flex: 2;
          min-width: 250px;
        }

        &.c5 {
          flex: 1;
          min-width: 100px;
        }
      }

      >._list_body ._col.c5 {
        color: #f8b62d;

        &.rc {
          color: #979797;
        }
      }
    }

    >table {
      width: 100%;

      >thead {
        background-color: lightgray;
      }

      >tbody>tr>td {
        text-align: center;
      }
    }
  }

  >.myServiceList_cond_paging {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
  }
}

// MyServiceListM
.myServiceListM {
  >.myServiceListM_header {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 14vw;
    }
  }

  >.myServiceListM_cond_type {
    border: 1px solid lightgray;
    font-size: 14px;

    >div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        border-bottom: 1px solid lightgray;
        padding: 5px 0;
      }

      >div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid lightgray;
        padding: 5px 0;

        &:first-child {
          border-left: none;
        }
      }
    }

    .selected {
      background-color: #f1aa25;
      font-weight: bold;
    }
  }

  >.myServiceListM_cond {
    display: flex;
    justify-content: space-between;
    margin: 1rem 1.5rem;

    .cond_opts {
      display: flex;
    }
  }

  >.myServiceListM_body {
    margin: 0 1.5rem;

    >.item {
      margin: 1.5rem 0;

      .fd {
        font-weight: bold;
      }

      // Field date
      .po {
        background-color: #f2f2f2;
      }

      // Panel Outer
      .pt {
        display: flex;
        justify-content: space-between;
        padding: 1rem;

        .ft {
          font-weight: bold;
          font-size: 1.5rem;
        }

        // Field Ttitle
        // .bt { } // Button
      }

      // Panel Title
      .pb {
        border-top: 2px solid #e6e6e6;
        padding: 1rem;
        display: flex;

        .bd {
          // flex: 1;
          width: 75%;

          .f1 {
            font-weight: bold;
          }

          // Field 1
          .f2 {
            word-break: break-all;
          }

          // Field 2
          .f3 {
            color: #f8b62d;
            font-weight: bold;
            font-size: 1.5rem;
          }

          // Field 3
          .f3.reject {
            color: #979797;
          }
        }

        // Body Desc
        .bi {
          width: 25%;

          >img {
            width: 100%;
          }
        }

        // Body Image
      }

      // Panel Body
    }
  }

  >.myServiceListM_cond_paging {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    button {
      opacity: 1;

      &.Mui-selected {
        background-color: #f3d965;
        color: white;
      }
    }
  }
}

// consignmentRegStep1
.sqRegMStep {
  width: 100%;
  padding: 2rem 1rem;

  // margin:2rem 1rem;
  .MuiOutlinedInput-root {
    border-radius: 0;
  }

  .MuiButton-root {
    border-radius: 0;
  }

  .unit {
    word-break: keep-all;
  }

  >.sqRegMStep_head {
    margin: 2rem;
    display: flex;
    justify-content: center;

    >img {
      width: 50%;
    }
  }

  .sqRegMStep_desc_guide {
    font-size: 0.6rem;
    background-color: lightgray;
    padding: 0.6rem;
    margin-bottom: 0.6rem;
  }

  >.colInput {
    margin: 1.3rem 0;
  }

  >.midTitle {
    font-weight: bold;
  }

  >.midButton {
    text-decoration: underline;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  >.saveBtnArea {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    >.saveBtn {
      width: 50%;
      background-color: lightgray;
      font-weight: bold;
      color: black;
      border-radius: 0;

      &.active {
        background-color: #f4cd00;
      }
    }
  }

  .alerthelper {
    color: red;
    font-size: 0.5rem;
    font-weight: normal;
  }

  .consignmentRegMStep_textbox {
    padding: 1rem;
    background-color: #f5f5f5;
    font-size: 0.6rem;
    margin-bottom: 1rem;

    .orangebold {
      color: orange;
      font-weight: bold;
    }
  }

  ._imageThumbBox {
    display: flex;
    flex-wrap: wrap;

    gap: 10px;

    ._imageThumb {
      flex: 1;
      min-width: 17%;

      >div {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
        overflow: hidden;
        border: 1px solid black;

        >div.delete_btn {
          >span {
            position: absolute;
            right: 0;
            top: 0;
            background-color: gray;
            color: white;
            width: 12px;
            height: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
          }
        }

        >div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          >span.orange {
            color: orange;
          }

          >img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

// sqRegPStep
.sqRegPStep {
  max-width: 1200px;
  width: 1200px;

  >div {
    max-width: 800px;
    margin: 0 auto;
  }

  >.sqRegPStep_head {
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >img {
      margin: 1rem 0;
      width: 30%;
    }
  }

  >.midTitle {
    // font-weight: bold;
    font-size: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .alerthelper {
    color: red;
    font-size: 0.8rem;
    font-weight: normal;
  }

  >.colInput {
    &.columnBody>div.colInput_body {
      flex-direction: column;
      align-items: flex-start;
    }

    margin: 1.3rem auto;
    display: flex;
    align-items: center;

    >div.colInput_head {
      width: 130px;
      font-size: 1.1rem;
    }

    >div.colInput_body {
      flex: 1;
      display: flex;
      align-items: center;

      >.MuiTextField-root {
        flex: 1;
      }

      >.dateRangePicker {
        flex: 1;
      }

      >.unit {
        margin-left: 0.5rem;
      }

      >.alerthelper {
        display: block;
      }
    }
  }

  ._desc_guide {
    font-size: 0.5rem;
    background-color: lightgray;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  >._desc_field {
    display: flex;
    justify-content: center;

    >.MuiTextField-root {
      width: 80%;
    }
  }

  >.saveBtnArea {
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    >.saveBtn {
      width: 20%;
      background-color: lightgray;
      font-weight: bold;
      color: black;
      border-radius: 0;

      &.active {
        background-color: #f4cd00;
      }
    }
  }

  .consignmentRegMStep_textbox {
    padding: 1rem;
    // background-color: #f5f5f5;
    font-size: 1rem;
    margin-bottom: 1rem;

    .orangebold {
      color: orange;
      // font-weight: bold;
    }
  }

  .consignmentItemPanel {
    >.consignmentItemPanel_body {
      background-color: white;

      .alerthelper {
        color: red;
        font-size: 0.5rem;
        font-weight: normal;
      }

      >.consignmentItemPanel_body_table {
        width: 100%;
        border-left: 2px solid black;
        border-right: 2px solid black;
        padding: 0 20px;

        .tableColTd.td2 {
          width: 100%;
        }

        .consignmentItemPanel_imagebox .consignmentItemPanel_image {
          min-width: 8%;
        }
      }
    }

    >.consignmentItemPanel_fields {
      display: flex;
      gap: 1rem;

      .colInput {
        margin: 1rem 0;
        display: flex;
        align-items: center;

        >div.colInput_head {
          width: 120px;
          font-size: 1.1rem;
          white-space: nowrap;
          flex: 1;
        }

        >div.colInput_body {
          flex: 1;
        }

        ._field_box {
          display: flex;
          align-items: center;

          >.MuiTextField-root {
            flex: 1;
          }

          >.unit {
            margin-left: 0.5rem;
          }
        }
      }

      &.siglefield>.colInput {
        width: 100%;

        >div.colInput_head {
          flex: 1;
        }

        >div.colInput_body {
          flex: 3;
        }
      }
    }

    ._image_guide {
      border: 1px solid gray;
      width: 260px;

      >img {
        width: 100%;
        height: 100%;
      }
    }

    .saveBtnArea {
      // margin-top: 2rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      >.saveBtn {
        width: 60%;
        background-color: lightgray;
        font-weight: bold;
        color: black;
        border-radius: 0;

        &.active {
          background-color: #f4cd00;
        }
      }
    }
  }

  ._imageThumbBox {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    >._imageThumb {
      flex: 1;
      min-width: 18%;

      >div {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
        overflow: hidden;
        border: 1px solid black;

        >div.delete_btn {
          >span {
            position: absolute;
            right: 0;
            top: 0;
            background-color: gray;
            color: white;
            width: 12px;
            height: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
          }
        }

        >div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          >span.orange {
            color: orange;
          }

          >img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.sqRegMDone {
  @media only screen and (min-width: 600px) {
    max-width: 600px;
    margin: auto;
  }

  .sqRegMDone_head {
    width: 100%;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    padding: 2rem;

    >img {
      width: 70%;
    }
  }

  .sqRegMDone_row {
    margin: 3.5rem 0;
    display: flex;
    justify-content: center;

    &._bigText {
      font-size: 1.4rem;
      font-weight: bold;
    }

    &._smallText {
      font-size: 0.8rem;
    }

    &._textCenter {
      text-align: center;
    }

    >.sqRegMDone_btns {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}

// ConsignmentItem
.consignmentItemPanel {
  >.consignmentItemPanel_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    // > .consignmentItemPanel_title { }
    // > .consignmentItemPanel_btn { }
  }

  >.consignmentItemPanel_body {
    background-color: #f7f7f7;
    padding: 1rem;

    >.consignmentItemPanel_body_btn {
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      text-decoration: underline;

      >div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .tableColTd.td1 {
      min-width: 150px;
    }

    .tableColTd.td2 {
      font-weight: bold;
    }

    .consignmentItemPanel_imagebox {
      display: flex;
      flex-wrap: wrap;
      gap: 5%;

      .consignmentItemPanel_image {
        flex: 1;
        min-width: 16%;

        img {
          width: 100%;
        }
      }
    }
  }
}

// consignmentRegItemDialog
.consignmentRegItemDialog {
  .MuiOutlinedInput-root {
    border-radius: 0;
  }

  .MuiButton-root {
    border-radius: 0;
  }
}

.consignmentRegItemDialogContent {
  .alerthelper {
    color: red;
    font-size: 0.5rem;
    font-weight: normal;
  }

  .colInput {
    margin-bottom: 0.8rem;
  }

  .consignmentRegItemDialogContent_field_box {
    display: flex;
    justify-content: center;
    align-items: center;

    >div.unit {
      margin-left: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      word-break: keep-all;
    }
  }

  .consignmentRegItemDialogContent_desc_guide {
    font-size: 0.5rem;
    background-color: lightgray;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .consignmentRegItemDialogContent_imagebox {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .consignmentRegItemDialogContent_image {
      flex: 1;
      min-width: 20%;

      >div {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
        overflow: hidden;
        border: 1px solid black;

        >div.delete_btn {
          >span {
            position: absolute;
            right: 0;
            top: 0;
            background-color: gray;
            color: white;
            width: 12px;
            height: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
          }
        }

        >div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          >span.orange {
            color: orange;
          }

          >img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.consignmentRegItemDialogActions {
  &.MuiDialogActions-root {
    width: 100%;
  }

  .saveBtnArea {
    // margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    >.saveBtn {
      width: 80%;
      background-color: lightgray;
      font-weight: bold;
      color: black;
      border-radius: 0;

      &.active {
        background-color: #f4cd00;
      }
    }
  }
}

// myServiceDetailView
.myServiceDetailView {
  .bb {
    border-bottom: 3px solid #e6e6e6;
  }

  .tb {
    border-top: 3px solid #e6e6e6;
  }

  .mPanel {
    padding: 1rem;

    >.tArea {
      font-weight: bold;
      font-size: 1.2rem;
    }

    // > .bArea { }
  }

  table.kvTable {
    >tbody>tr {
      >td:first-child {
        min-width: 100px;
      }

      >td:last-child {
        font-weight: bold;
      }
    }
  }

  >.myServiceDetailView_header {
    padding: 1rem 0 0 0;

    >.backBtnArea {
      display: flex;
      align-items: center;
    }

    >.titleArea {
      margin: 0 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      >.titleStr {
        font-size: 1.3rem;
        font-weight: bold;
      }

      >.idStr {
        font-size: 1rem;
      }
    }

    >.dateArea {
      margin: 0 1rem;
      font-weight: bold;
    }
  }

  >.myServiceDetailView_items {
    padding: 0 1rem;

    >.itemDetail {
      padding: 1rem 0;
      border-bottom: 1px solid #e6e6e6;

      >.itemDetail_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >.seqArea {
          flex: 0.8;
          font-weight: bold;
        }

        >.imgArea {
          flex: 2;

          >img {
            width: 100%;
          }
        }

        >.descArea {
          flex: 5;

          >.statusStr {
            color: #f4cd00;
            font-weight: bold;
            font-size: 1.1rem;
          }

          >.descStr {
            font-weight: bold;
            font-size: 1.4rem;
          }

          // > .countStr {          }
        }

        >.btnArea {
          flex: 0.5;
        }
      }

      >.openedArea>.editBtn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        text-decoration: underline;
      }
    }
  }

  >.myServiceDetailView_continue {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
    height: 5rem;

    >div {
      text-decoration: underline;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  ._imageThumbBox {
    display: flex;
    flex-wrap: wrap;

    gap: 10px;

    ._imageThumb {
      flex: 1;
      min-width: 17%;

      >div {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
        overflow: hidden;
        border: 1px solid black;

        >div.delete_btn {
          >span {
            position: absolute;
            right: 0;
            top: 0;
            background-color: gray;
            color: white;
            width: 12px;
            height: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
          }
        }

        >div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          >span.orange {
            color: orange;
          }

          >img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.buttonBox {
  &.MuiButton-root {
    &.yellow {
      border-radius: 0;
      background-color: #FFC448;
      border-color: #FFC448;
      border-style: solid;
      color: #343538;
    }

    &.gray {
      border-radius: 0;
      color: #ffffff;
      background-color: #343538;
      opacity: 0.3;
      border-style: solid;
      border-color: #343538;
    }

    &.gray808080 {
      border-radius: 0;
      color: #ffffff;
      background-color: #808080;
      border-style: solid;
      border-color: #808080;
    }

    &.black {
      border-radius: 0;
      color: #ffffff;
      background-color: #343538;
      border-style: solid;
      border-color: #343538;

      &:hover {
        background-color: #343538;
        opacity: 0.8;
      }
    }

    &.white {
      border-radius: 0;
      color: #343538;
      background-color: white;
      border-style: solid;
      border-color: rgba(52, 53, 56, 0.1);
    }

    &.white-border-black {
      border-radius: 0;
      color: #343538;
      background-color: white;
      border-style: solid;
      border-color: black;
    }
  }
}

.NoOutline_Select.MuiInputBase-root {
  color: #7f7f7f;

  >.MuiOutlinedInput-notchedOutline {
    border: 0;
  }
}

// ReservationView
.reservationView {
  padding-top: 30px;

  a {
    color: gray;
  }

  .head1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .head2 {
    font-size: 16px;
    padding-left: 5px;
    margin-bottom: 5px;
  }

  .head3 {
    font-size: 14px;
    padding-left: 15pxl;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // .ReservationViewBody {  } // width: props.isMobile ? undefined : "60%;
}

// ReservationTimeSelector
.reservationTimeSelector {
  .ampm {
    display: flex;

    .title {
      padding: 10px 20px 10px 30px;
    }

    margin-bottom: 30px;

    .content {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .timeslot {
    border: 1px solid black;
    width: 60px;
    margin: 0px 10px 10px 10px;
    padding: 7px 13px 7px 0px;
    text-align: right;

    &.reject {
      color: #e2e3e4;
      background-color: #cecece;
      border: 1px solid gray;
    }

    &.booked {
      color: #e2e3e4;
      background-color: #cecece;
      border: 1px solid gray;
      // background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='gray' /><path d='M0 99 L99 0 L100 1 L1 100' fill='gray' /></svg>")`,
    }

    &.available {
      background-color: white;
    }

    &.notavailable {
      color: #e2e3e4;
      border: 1px solid gray;
      background-color: #bebebe;
    }
  }
}

// ReservationRequestNew
.reservationRequestNew {
  // .MuiTypography-body1 { }
  width: 100%;
  display: flex;
  flex-direction: column;

  .row {
    padding: 10px;
    border-bottom: 1px solid black;
    margin-bottom: 10px;

    .title {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }

    .formRow {
      display: flex;
      margin-bottom: 10px;
    }
  }
}

// ReservationCard
.reservationCard {
  width: 100%;
  max-width: 350px;
  border: 2px solid black;
  padding: 22px 22px 22px 25px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // fontFamily: "Times",
  .title {
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 35px;
  }

  .closebtn {
    position: absolute;
    width: 100%;
    text-align: right;
    top: 20px;
    right: 10px;
  }

  .content {
    display: flex;
    // justifyContent: center;
    align-items: flex-start;
    flex-direction: column;

    .row {
      font-size: 12px;
      display: flex;
      margin-bottom: 10px;

      &.top {
        margin-bottom: 35px;
      }

      &.bottom {
        margin-bottom: 40px;
      }

      .name {
        width: 100px;
      }

      .value {
        &.bold {
          font-weight: bold;
        }
      }
    }
  }

  .actions {
    .MuiButton-root.MuiButton-text.white {
      &:first-child {
        margin: 0 20px 0 0;
      }

      padding: 2px 4px;
      border: 1px solid #b6b6b6;
    }

    display: flex;
    justify-content: space-around;
    // &:first-child { }
  }
}

// ReservationCalendar
.reservationCalendar {
  .calendarTitle {
    display: flex;
    justify-content: space-around;
    padding: 0 5rem;
  }

  .calendarBody {
    display: flex;
    justify-content: center;

    & table {
      margin-top: 20px;
      border-collapse: collapse;

      & thead tr th {
        // border: '1px solid black',
        width: 40px;
      }

      & tbody tr td {
        min-height: 30px;

        & .dateDiv {
          display: flex;
          flex-direction: column;
          min-height: 50px;

          & .date {
            display: flex;
            justify-content: center;
          }

          & .dateContent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    & tbody {
      & .day {

        // backgroundColor: 'lightgray',
        &.ADL_FREE {
          background-color: white;
        }

        &.ADL_ALLOW {
          background-color: white;
        }

        &.ADL_REJECT {
          background-color: white;
          color: gray;

          & .dateContent {
            font-size: 9px;
          }
        }

        &.ADL_EACH_TIME {
          background-color: white;
        }

        &.today .date span {
          background-color: yellow;
          border-radius: 10px;
          padding: 0 2px;
        }

        &.today {
          color: #e2e3e4;
          //   backgroundColor: #555555;
        }

        &.prevday {
          color: #e2e3e4;
          //   backgroundColor: #555555;
        }
      }
    }

    & .day {

      // border: 1px solid black;
      &.sun {
        // color: red;
        border-left: unset;
      }

      &.sat {
        border-right: unset;
      }
    }

    & .lastWeek .day {
      border-bottom: unset;
    }
  }
}

// ProfileView
.profileView {
  & .inputborder {
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiOutlinedInput-root {
      border-radius: 0;
      border: 1px solid black;
    }

    & .MuiInput-underline:before {
      border: none;
    }

    & .MuiInput-underline:after {
      border: none;
    }
  }

  & .btn_search {
    margin-left: 10px;
    border: 3px solid;
    border-radius: 0px;
    padding: 5px 25px;
  }
}

.profileViewProfile {
  display: flex;
  justify-content: center;
  align-items: center;

  & .content {
    width: 100%;
    max-width: 480px;

    & .row {
      width: 100%;
      margin: 20px 0px;

      & .body {
        margin: 10px 0px;
        display: flex;
      }
    }
  }
}

// PrivacyView
.privacyView {
  @media only screen and (min-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .PrivacyViewBody {
    @media only screen and (min-width: 600px) {
      width: 70%;
    }
  }

  & a {
    color: gray;
    cursor: pointer;
  }

  padding-top: 30px;
}

// PersonalPayView
.personalPayView {
  padding-top: 30px;

  & .MuiContainer-root {
    padding-left: 30px;
    padding-right: 30px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Times;
}

.personalPayViewItemListView {
  width: 100%;

  @media only screen and (min-width: 600px) {
    width: 75%;
    max-width: 1280px;
  }

  & .listArea {
    padding-top: 10px;
  }

  & .orderingDiv {
    & span.orderingSpan {
      cursor: pointer;
    }

    & .bold {
      font-weight: bold;
    }
  }
}

// OrderReceiptDialog
.orderReceiptDialog {
  & .MuiBackdrop-root {
    opacity: 0 !important;
  }

  & .MuiPaper-root {
    width: 100%;
    margin: 0.5em;
    max-width: 26em;
    max-height: 80%;
    border: 3px solid black;

    & .MuiDialogContent-root {
      padding: 10px;
      overflow: auto;

      & .content-body {
        & .ordermeta {
          font-size: 10px;
          margin: 1.5em 0;
          display: flex;
          justify-content: space-between;

          & .lr {
            display: flex;
            flex-direction: column;
          }
        }

        & .itemDetail {
          display: flex;
          justify-content: space-around;
          padding-bottom: 2em;

          & .desc {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-top: 2em;
          }
        }

        & .orderinfo {
          display: flex;
          flex-direction: column;

          & .row {
            display: flex;
            justify-content: space-between;
            padding: 1.5em 2em;

            &.sl {
              border-bottom: 0.1em solid lightgray;
            }

            &.dl {
              border-bottom: 1em double lightgray;
            }
          }
        }
      }
    }
  }
}

// OrderItemDiv
.orderItemDiv {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  & .topOutline {
    margin: 0 0.6rem;
    display: flex;
    justify-content: space-between;
  }

  & .itemOutline {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid #e5e5e5;

    & .col {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .deliverOutline {
    border-bottom: 1px solid #e5e5e5;
    padding: 1rem;

    & table {
      width: 100%;

      & td.label {
        font-weight: bold;
        min-width: 4rem;
        text-align: center;
        vertical-align: top;
      }
    }
  }
}

// OrderListView
.orderListView {
  & .title {
    padding: 1.5rem 2rem;
  }

  & ._header {
    display: flex;
    flex-direction: column;

    & .row {
      &:last-child {
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #cbcbcb;
      }

      display: flex;
      justify-content: space-around;
      padding: 1rem;

      & .vcell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        & span {
          text-align: center;
        }
      }

      & .hcell {
        display: flex;

        & span {
          margin-left: 10px;
        }
      }
    }
  }

  & .body {
    & .title {
      font-weight: bold;
      padding: 2rem 0 1.5rem 0.5rem;
      color: #707070;
      font-size: 1rem;
    }
  }
}

// NoticeView
.noticeView {
  padding-top: 30px;

  & .noticeTable {
    & .MuiTableCell-root {
      border-bottom: 3px solid #dcdcdc;
    }
  }
}

// TextFieldStyled
.textFieldStyled {
  & .MuiOutlinedInput-root {
    border-radius: 0;

    & .MuiOutlinedInput-input {
      padding: 12px 12px;
    }
  }
}

// NewOrderLayoutM
.newOrderLayoutM {
  width: 100%;

  & .area1 {
    background-color: #f2f2f2;
    padding: 0.5rem 0;
  }

  & .area2 {
    background-color: white;
    padding: 1.5rem 1.5rem;

    & .kv {
      font-weight: bold;
      padding: 0.3rem 0;
    }

    & .fieldDiv {
      margin: 1.5rem 0;
    }
  }
}

// NewOrderLayout
.newOrderLayout {
  background-color: #f2f2f2;
  padding: 2rem;
  width: 100%;
  max-width: 1281px;
  display: flex;
  justify-content: center;

  & .area1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f2f2f2;
  }

  & .area2 {
    display: flex;
    width: 100%;
    background-color: #f2f2f2;
    margin-top: 1rem;

    & .user_info {
      flex: 1;
      background-color: white;
    }

    & .delivery_info {
      margin-left: 1rem;
      flex: 1;
      background-color: white;
    }
  }

  & .area3 {
    padding: 3rem 35%;
  }

  & .infoArea {
    padding: 2.5rem;
  }

  & .mybag_info {
    display: flex;
    flex-direction: column;
    background-color: white;

    & .cartlist {
      display: flex;
      flex-wrap: wrap;
    }

    & .payinfo {
      padding: 2rem;
    }
  }

  // & .order_btn { }

  & .infoTitle {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 20px;
  }

  & .fieldDiv {
    margin: 2rem 0;
  }
}

// ItemOptionDiv
.itemOptionDiv {
  display: inline-block;
  border: 1px black solid;
  padding: 5px;
  margin: 5px;
  background-color: lightGray;

  &.selected {
    background-color: white;
  }
}

// FooterM
.footerM {
  & .footerImg {
    // marginTop: calcPx(90),
    margin-top: 7.978723404vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & img {
      width: 100%
    }
  }

  & .footerArea {
    background-color: #26120b;
    color: white;
    // padding: calcPx(40),
    padding: 3.546099291vw;

    &>.logosArea {
      // padding: `${calcPx(100)} ${calcPx(20)}`,
      padding: 8.865248227vw 1.773049645vw;
      border-bottom: 1px solid white;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &>img {
        // height: calcPx(40),
        height: 3.546099291vw;
      }
    }

    &>.info1Area {
      // padding: calcPx(120),
      padding: 10.63829787vw;
      display: flex;
      justify-content: space-around;
      font-weight: bold;
      font-size: 5vw;
    }

    &>.info2Area {
      display: flex;

      &>.info {
        flex: 1;
        font-size: 2.5vw;

        &>.title {
          font-weight: bold;
          font-size: 3.5vw;
          // marginBottom: calcPx(10),
          margin-bottom: 0.886524823vw;
        }
      }
    }

    &>.bottomArea {
      // padding: `${calcPx(120)} ${calcPx(20)}`,
      padding: 10.63829787vw 1.773049645vw;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &>div {
        display: flex;
        justify-content: center;
        text-align: center;

        &>img.logo {
          // height: calcPx(50),
          // marginBottom: calcPx(50)
          height: 4.432624113vw;
          margin-bottom: 4.432624113vw;
        }
      }
    }
  }
}

// AuthPwdDialog
.authPwdDialog {
  & .MuiPaper-root {
    width: 70%;
    max-width: 30rem;
    height: 15rem;
    font-size: 1rem;
  }

  & .MuiDialogContent-root {
    width: 100%;
    height: 100%;
  }

  & .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  & .title {
    font-weight: bold;
  }
}

// Carousel.dotsInnerRect
.carousel_dotsInnerRect {
  & .slick-slider {
    & .slick-dots {
      bottom: 15px;

      & li button:before {
        content: '"▪"';
        opacity: 0.7;
        color: white;
        font-size: 20px;
      }

      & li.slick-active button:before {
        opacity: 1;
      }
    }
  }
}

// CountNumberInput
.countNumberInput {
  & .Wrapper {
    display: flex;
    flex-wrap: nowrap;

    & .subWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      min-height: 40px;
      color: gray;
      // border: props.isNoBorder === true ? "" : "2px solid lightgray",

      &.num {
        border-left: none;
        border-right: none;
      }
    }
  }

  &.sizeSmall {
    & .Wrapper {
      & .subWrapper {
        min-width: 20px;
        min-height: 20px;
      }
    }
  }
}

// DateRangePicker
// .dateRangePicker {}
.dateRangePickerPopper {
  // background-color: #ffffff;

  & .MuiPaper-root {
    ._calendarBody {
      width: 308px;
      user-select: none;
    }
  }

  & div._dayHead {
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  & div._dayBlank {
    min-width: 44px;
    width: 44px;
    height: 40px;
    opacity: 0;
    flex: 1
  }

  & div._dayBox {
    cursor: pointer;
    // min-width: 14%;
    min-width: 44px;
    width: 44px;
    height: 40px;
    // line-height: 24px;
    overflow: hidden;

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & ._day {
      height: 24px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      &._from {
        // &._half { background: linear-gradient(90deg, #ffffff 50%, #fae680 50%);}
        background-color: #f4cd00;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &._to {
        // &._half { background: linear-gradient(90deg, #fae680 50%, #ffffff 50%);}

        background-color: #f4cd00;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &._mid {
        background-color: #fae680;
      }

      &._over {
        background-color: lightgray;
      }
    }

    &>div._cm {
      display: flex;
      justify-content: center;
      // height: 0.6rem;
      height: 16px;
      font-size: 8px
    }
  }
}

.monthPickerPopper {
  .monthArea {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .monthUnit {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 22%;
      height: 4rem;
      cursor: pointer;

      &._over {
        background-color: lightgray;
      }
    }
  }
}


// InputBox
.inputBox {

  // width: '100%',
  // marginBottom: '20px',
  &.MuiFormControl-root {
    // height: '40px',
    width: 100%;
    // border:
    //   props.boxStyle === "white-border-black"
    //     ? `solid ${props.border ? props.border : "2px"} black`
    //     : `solid ${props.border ? props.border : "2px"} rgba(52, 53, 56, 0.1)`,
    // marginBottom: '20px'
  }

  & .MuiOutlinedInput-input {
    padding: 0px 10px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0,
  }

  & .MuiInputBase-input {
    height: 36px;
  }

  &.multiline .MuiInputBase-input {
    height: unset;
    // fontFamily: "'Times', 'Nanum Myeongjo'",
  }

  & .MuiOutlinedInput-multiline {
    // padding: 'unset',
    padding: 5px 5px 5px 10px;
  }

}

// MenuPanel
.menuPanel {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  position: relative;

  &>.body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    // padding: calcPx(20),
    padding: 1.773049645vw;

    &.blur {
      filter: blur(2px);
    }

    &>.titleArea {
      // padding: `${calcPx(20)} ${calcPx(10)} 0px ${calcPx(10)}`,
      padding: 1.773049645vw 0.886524823vw 0px 0.886524823vw;

      // fontSize: calcPx(45),
      font-size: 3.989361702vw;
      font-weight: bold;
    }

    &>.descArea {
      // padding: `0px ` + calcPx(10),
      // fontSize: calcPx(32),
      padding: 0px 0.886524823vw;
      font-size: 2.836879433vw;
    }

    &>.imageArea {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-height: 45%;

      &>img {
        // width: "100%",
        height: 100%;
      }
    }

    &>.buttonArea {
      display: flex;
      justify-content: space-between;

      &>.buttonBox {
        cursor: pointer;
        // width: calcPx(225),
        // height: calcPx(80),
        // fontSize: calcPx(28),
        width: 19.94680851vw;
        height: 7.092198582vw;
        font-size: 2.482269504vw;
        font-weight: 400;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        &.left {
          color: black;
        }

        &.right {
          background-color: black;
          color: white;
        }
      }
    }
  }

  &>.disabled {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &>.closeIcon {
      position: absolute;
      top: 1vh;
      right: 2vw;

      &>img {
        width: 3vw;
      }
    }

    &>.span1 {
      // fontSize: calcPx(32),
      font-size: 2.836879433vw;
      font-weight: bold;
    }

    &>.span2 {
      // fontSize: calcPx(24),
      font-size: 2.127659574vw;
    }

    &>.span3 {
      // fontSize: calcPx(24),
      font-size: 2.127659574vw;
    }

  }
}

// OutlineBox
.outlineBox {
  width: 100%;
  padding: 8px;
  background-color: #f3f3f3;

  & .innerBox {
    padding: 8px;
    border: 2px solid lightgray;
  }
}

// PriceSlider
.priceSlider {
  &.hideDesc .valueDiv span {
    width: 2px;
  }

  &.hideDesc .sliderDiv {
    padding-left: 0px;
    padding-right: 0px;
  }

  & .valueDiv {

    display: flex;
    justify-content: space-between;
    overflow-x: hidden;

    & span {
      width: 102px;
      text-align: center;
      white-space: nowrap;
      // border: "1px solid black",
    }
  }

  & .sliderDiv {
    display: flex;
    padding-left: 50px;
    padding-right: 50px;
  }


  .rowReverser {
    display: flex;
    flex-direction: column-reverse;
  }
}

// TextComponent
.titleText {
  width: 150px;
  height: 24px;
  // fontFamily: AppleSDGothicNeo;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #343538;
  margin-bottom: 8px;
}

.subTitleText {
  // fontFamily: AppleSDGothicNeo;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #737680;
}

.descText {
  // fontFamily: AppleSDGothicNeo;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #343538;
}

.topMenuText {
  font-size: 13px;
  margin: 5px;
  font-weight: bold;
}

.bigTitleText {
  height: 48px;
  font-family: Helvetica;
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 1.2px;
  color: #343538;
}

.itemDetailTitleText {
  // height: '48px',
  // fontFamily: 'AppleSDGothicNeo',
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.95px;
  color: #343538;
}

.itemInfoDescText {
  // font-samily: AppleSDGothicNeo;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.3px;
  color: #737680;
}

.itemInfoPriceText {
  width: 240px;
  height: 48px;
  // font-samily: 'AppleSDGothicNeo',
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.95px;
  color: #343538;
}


// AddressBox
.addressBox {
  & .toolbar {
    display: flex;
    justify-content: flex-end;
  }

  & .body {
    display: flex;
    justify-content: center;
  }

  .inlineButton {
    display: inline-block;
    vertical-align: top;

    & button {
      vertical-align: middle;
    }
  }
}

// CartList
.cartList {
  & .displaynone {
    display: none;
  }

  padding-top: 40px;

  @media only screen and (min-width: 600px) {
    // [theme.breakpoints.between("xs", "sm")]: {
    padding-top: 0px;
  }

  & table.cartListTable {
    border-collapse: collapse;
    width: 100%;
  }

  & table.cartListTable>thead>th {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  & table.cartListTable>tbody>tr>td {
    border-bottom: 1px solid #efefef;
    padding-top: 24px;
    padding-bottom: 24px;

    & .countNumberInput {
      width: 100px;
      height: 40px;

      & td {
        padding: 0px;
      }
    }

    &.colCheck {
      width: 42px;
    }

    &.colDesc {
      & .content {
        display: flex;
      }

      &>div {
        display: inline-block;
      }

      // & .imageArea {
      // height: "120px",
      // & img { width: "120px" },
      // },
      & .descArea {
        padding: 45px 10px 0px 20px;

        & .desc {
          display: -webkit-box;
          margin: 0 auto;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &.colCount {
      width: 128px;
      text-align: center;
    }

    &.colPrice {
      text-align: center;
      padding-left: 10px;
    }

    &.colClear {
      width: 42px;
    }

    &.colTotal {
      & .topOutline {
        display: flex;

        & .imageArea img {
          width: 120px;
        }

        & .infoArea {
          flex: 1 1;
          padding-left: 8px;
        }
      }
    }
  }

  & table.cartListTable .Mui-checked {
    color: yellow;

    & span.MuiIconButton-label {
      box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 1);

      // WebkitBoxShadow: inset 0px 0px 0px 4px rgba(255,255,255,1);;
      // MozBoxShadow: inset 0px 0px 0px 4px rgba(255,255,255,1);;
      background-color: black;
    }
  }
}


// FindPwdBox
.findPwdBox {
  & .bodyArea {
    & .bodyRow {
      font-weight: bold;
      color: #555555;
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;

      & .label {
        flex: 1;
        display: flex;
        font-size: 0.8rem;
        align-items: center;
        white-space: nowrap;
      }

      & .body {
        display: flex;
        flex: 2;
        justify-content: flex-end;

        & .input {
          width: 100%;

          & .helperDiv {
            padding-left: 16px;
            padding-top: 1px;
            color: rgba(0, 0, 0, 0.54);
            margin: 0;
            font-size: 0.75rem;
            text-align: left;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
          }

          & .green {
            color: green;
          }

          & .red {
            color: red;
          }
        }

        & .action {
          padding: 8px 0px 0px 4px;
        }
      }
    }
  }

  .spinner {
    color: #6798e5;
    animation-duration: 550ms;

  }
}


// JoinBox
.joinBox {
  &.m {
    padding: 1.5rem 5.319148936vw;

    .bodyArea .bodyRow .body .helperDiv {
      font-size: 10px;
      ;
    }
  }

  // padding: m ? `1.5rem ${calcPx(60)}` : undefined,
  // padding: m ? `1.5rem 5.319148936vw` : undefined,
  width: 100%;

  & .bodyArea {
    & .bodyRow {
      margin-bottom: 1.5rem;
      display: flex;
      flex-flow: column;

      & .label {
        width: 200px;
      }

      & .body {
        display: flex;

        & .input {
          // width: 300px;
          width: 100%;

          & .helperDiv {
            padding-left: 16px;
            padding-top: 1px;
            color: rgba(0, 0, 0, 0.54);
            margin: 0;
            // font-size: m ? "10px" : 0.75rem;
            font-size: 0.75rem;
            text-align: left;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
            // position: absolute;
          }

          & .green {
            color: green;
          }

          & .red {
            color: red;
          }
        }

        & .action {
          padding: 8px 0px 0px 4px;
        }
      }
    }
  }

  & table tbody tr td.label {
    padding-bottom: 18px;
    padding-left: 18px;
    padding-right: 18px;
  }

  & table tbody tr td.addon {
    padding-bottom: 2px;
    display: flex;
    white-space: nowrap;
  }

  .spinner {
    color: #6798e5;
    animation-duration: 550ms;
  }

  // .adjustHeight {
  // height: 40px",
  // marginBottom: 20px",
  // alignItems: center",
  // display: flex"
  // }

  .inputEl {
    &.MuiOutlinedInput-root {
      border-radius: 0px;

      & .MuiInputBase-input {
        padding: 14px 12px;
      }
    }
  }

  .blackCheckbox {
    &.Mui-checked {
      color: gray;
    }

    // &>.MuiIconButton-label {}
  }
}

// LoginRequired
.loginRequired {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  height: 100%;
}

// MobileMainSlide
.mobileMainSlide {
  position: relative;
  // width: calcPx(1044),
  // height: calcPx(1196),
  width: 92.55319149vw;
  height: 106.0283688vw;

  &>.slick-slider>.slick-dots {
    bottom: 10px;

    &>li {
      margin: 0;
      width: 12px;
    }

    &>li>button:before {
      color: #ffffff;
      opacity: 1;
    }

    &>li.slick-active>button:before {
      color: #929292;
    }
  }

  & .innerCI {
    position: relative;
    // width: calcPx(1044),
    // height: calcPx(1196),
    width: 92.55319149vw;
    height: 106.0283688vw;
    background-color: lightgray;

    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &>.overDiv {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: white;
      // padding: `${calcPx(40)} ${calcPx(40)} ${calcPx(120)} ${calcPx(40)}`,
      padding: 3.546099291vw 3.546099291vw 10.63829787vw 3.546099291vw;

      &>.overTitle {
        padding: 0 3.546099291vw;
        font-size: 10.63829787vw;
        font-weight: bold;
      }

      &>.overDescription {
        // padding: `0 ${calcPx(60)}`,
        // fontSize: calcPx(50),
        padding: 0 5.319148936vw;
        font-size: 4.432624113vw;
      }
    }
  }
}


// MobileSubSlide
.mobileSubSlide {

  position: relative;
  // width: calcPx(1044),
  // height: calcPx(380),
  width: 92.55319149vw;
  height: 33.68794326vw;

  &>.slick-slider>.slick-dots {
    bottom: 10px;

    &>li {
      margin: 0;
      width: 12px;
    }

    &>li>button:before {
      color: #ffffff;
      opacity: 1,
    }

    &>li.slick-active>button:before {
      color: #929292;
    }
  }

  & .innerCI {
    position: relative;
    // width: calcPx(1044),
    // height: calcPx(380),
    width: 92.55319149vw;
    height: 33.68794326vw;
    background-color: lightgray;

    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &>.overDiv {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: white;
      // padding: `${calcPx(40)} ${calcPx(40)} ${calcPx(120)} ${calcPx(40)}`,
      padding: 3.546099291vw 3.546099291vw 10.63829787vw 3.546099291vw;

      &>.overTitle {
        // padding: `0 ${calcPx(40)}`,
        padding: 0 3.546099291vw;
        // fontSize: calcPx(120),
        font-size: 10.63829787vw;
        display: flex;
        justify-content: center;
        font-weight: bold;
      }

      &>.overDescription {
        // padding: `0 ${calcPx(60)}`,
        // fontSize: calcPx(50),
        padding: 0 5.319148936vw;
        font-size: 4.432624113vw;
      }
    }
  }
}


// OrderCard
.orderCard {
  background-color: #efefef;
  margin-bottom: 8px;
  padding: 16px;

  & div.contents {
    border: 2px solid white;
    padding: 8px;

    & div.header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & div.body {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & img {
        height: 72px;
        width: 72px;
      }
    }
  }
}

// OrderDetailBox
.orderDetailBox {
  & table.adsc_simpletable {
    width: 100%;

    & tbody tr.topdownLined {
      & th {
        border-top: 2px solid black;
      }
    }
  }
}


// PayingBox
.payingBox {
  background-color: #fafafa;
  padding: 40px 16px;

  & .titleArea {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.1px;
    color: #343538;
    padding-bottom: 40px;
  }

  & .itemArea {
    & .selectedCartItem {
      display: flex;
      border-bottom: 1px solid #ededed;
      margin: 8px;
      padding-bottom: 8px;
      margin-top: 24px;

      & .imageArea {
        margin-right: 8px;

        & img {}
      }

      & .descArea {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 4px;

        & .name {}

        & .brand {}

        & .price {}
      }

      & .clearArea {
        margin-left: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  & .resultArea {
    border-top: 1px solid black;
    margin-top: 20px;

    & .addedPrice {
      margin: 20px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .label {
        font-size: 18px;
        font-weight: bold;
      }

      & .content {
        font-size: 18px;
      }
    }

    & .totalPrice {
      border-top: 1px solid black;
      margin: 20px 0px 20px 0px;
      padding: 20px 40px 20px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .label {}

      & .content {
        font-size: 28px;
      }
    }
  }
}

// SideCart
.sideCart {
  user-select: none;
  position: fixed;
  width: 300px;
  height: 100%;

  top: 0;
  right: -300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px;
  z-index: 2;

  background-color: white;

  &.cartOpen {
    right: 0;
    box-shadow: 0px 0px 5px 2px #ddd;
  }

  transition: right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
  boxShadow 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  // transition: theme.transitions.create(["right", "boxShadow"], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),

  & .mHalfTop {
    flex: 0 0 20px;

    & .mLogoArea {
      height: 58px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: '5px 15px',
      border-bottom: 1px solid black;
    }
  }

  & .mHalfMid {
    display: flex;
    overflow: auto;
    flex-direction: column;
    flex-grow: 1;

    & .mMenuItems {
      padding-top: 15px;

      & .mMenuItem {
        color: #989898;
        font-weight: bold;
        padding: 10px 17px;

        & .mMenuItem_name {
          display: flex;
          align-items: end;

          &.opened {
            color: #585858;
          }
        }
      }
    }
  }

  & .mHalfBottom {
    flex: 0 0 20px;
    text-align: center;

    & a {
      color: #707070;
    }

    padding-bottom: 20px;
  }

}

// TopPopper
.topPopper {
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 7px;
  background-color: white;
}

// AboutView 
.aboutView {
  padding-top: 30px;

  @media only screen and (min-width: 600px) {
    // [theme.breakpoints.up("sm")]: {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .AboutViewBody {
    @media only screen and (min-width: 600px) {
      // [theme.breakpoints.up("sm")]: {
      width: 70%;
    }
  }

  // fontFamily: 'Times',
  & a {
    color: gray;
    cursor: pointer;
  }

  & .title {
    font-weight: bold;
    padding: 30px;
  }

  & .body {
    padding: 30px 30px 30px 50px;
  }

}

// AgreementView
.agreementView {
  @media only screen and (min-width: 600px) {
    // [theme.breakpoints.up("sm")]: {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .ViewBody {
    @media only screen and (min-width: 600px) {
      // [theme.breakpoints.up("sm")]: {
      width: 70%;
    }
  }

  & a {
    color: gray;
    cursor: pointer;
  }

  padding-top: 30px;
}

// CartView
.cartView {
  padding-top: 30px;

  & .MuiContainer-root {
    padding-left: 30px;
    padding-right: 30px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  .cartViewBody {
    width: 100%;
    padding-top: 50px;

    @media only screen and (min-width: 1200px) {
      // [theme.breakpoints.up("lg")]: { 
      width: 1140px;
    }

    @media only screen and (max-width: 1200px) and (min-width: 600px) {
      // [theme.breakpoints.between("sm", "md")]: { 
      width: 87%;
    }

    @media only screen and (max-width: 600px) {
      // [theme.breakpoints.between("xs", "sm")]: { 
      padding-top: 20px;
    }

    & .cartList {
      padding-bottom: 100px;
    }

    & .payingBox {}
  }
}

// FavoriteView
.favoriteView {
  padding-top: 30px;

  & .MuiContainer-root {
    padding-left: 30px;
    padding-right: 30px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  .favoriteViewBody {
    width: 100%;

    @media only screen and (min-width:600px) {
      // [theme.breakpoints.up("sm")]: {
      width: 82%;
      max-width: 1280px;
    }
  }
}

// FindPwdView
.findPwdView_todoremove {
  padding-top: 30px;
  min-height: 600px;

  & .findpwdBoxDiv {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }
}

// GuideView
.guideView {
  @media only screen and (min-width:600px) {

    // [theme.breakpoints.up("sm")]: {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .GuideViewBody {
    @media only screen and (min-width:600px) {

      // [theme.breakpoints.up("sm")]: {
      width: 70%;
    }
  }

  & a {
    color: gray;
    cursor: pointer;
  }

  padding: 30px;

  & table.guideTable {
    border-collapse: collapse;
    width: 100%;

    & td {
      padding: 15px;
      border: 2px solid gray;
    }
  }
}


// JoinView
.joinView {
  &.mo {
    min-height: 400px;

    & .joinBoxDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
    }
  }

  &.pc {
    max-width: 1280px;
    min-height: 600px;
    margin: auto;
    padding: 0 2rem;
    display: flex;
    justify-content: center;

    & .joinBoxDiv {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 5rem 0;
    }
  }
}


// ReservationItem
.reservationItem {
  border-bottom: 1px solid #555555;

  & .ReservationItemBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 12px;

    // & span.title {}
    & span.tail {
      display: flex;
      align-items: center;
    }
  }

  // & .reservationRequested {}
  & .reservationReserved {
    & span.title {
      font-weight: bold;
    }

    & span.tail {
      text-decoration: underline;
    }
  }

  & .reservationRejected {
    & span.tail {
      color: red;
    }
  }

  & .reservationCancelled {
    & span.tail {
      color: red;
    }
  }
}

// MyReservationView
.myReservationView {
  @media only screen and (min-width:600px) {

    // [theme.breakpoints.up("sm")]: {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  & .MyReservationViewBody {
    @media only screen and (min-width:600px) {

      // [theme.breakpoints.up("sm")]: {
      width: 60%;
    }
  }

  .modifyDialog {
    & .MuiPaper-root {
      @media only screen and (min-width:600px) {
        // [theme.breakpoints.up("sm")]: {
        width: 500px;
      }

      @media only screen and (min-width:600px) {
        // [theme.breakpoints.down("sm")]: {
        width: 95%;
        margin: 5%;
        height: calc(100% - 200px);
      }
    }
  }
}


// BarPopOverM 
.barPopOverM {
  position: relative;

  & .flexcenter {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &>.children {
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid black;
    border-top: none;
    background-color: white;
    z-index: 100;
  }

  &>.title {
    border: 1px solid black;
    // height: calcPx(114),
    // fontSize: calcPx(36),
    height: 10.10638298vw;
    font-size: 3.191489362vw;
    display: flex;

    &.opened {
      border-bottom: 1px solid white;
    }

    &>.name {
      flex: 1;
      margin: auto;
    }

    &>.button {
      position: absolute;
      // top: calcPx(44),
      // right: calcPx(28),
      top: 3.90070922vw;
      right: 2.482269504vw;
    }
  }

}

// DetailInfo
.detailInfo {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-top: 10px;

  & .detailinfoRow {
    display: flex;
    margin-top: 1.5rem;

    & .detailinfoLabel {
      flex: 0.35;
      font-weight: bold;
      margin-right: 10px;
    }

    & .detailinfoContent {
      flex: 1
    }
  }
}

// ItemInfo
.itemInfo {


  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .itemInfoBody {
    width: 100%;
    padding-top: 50px;

    @media only screen and (min-width: 1200px) {
      // [theme.breakpoints.up("lg")]: { 
      width: 1200px;
    }

    @media only screen and (max-width: 1200px) and (min-width: 600px) {
      // [theme.breakpoints.between("sm", "md")]: {
      width: 87%;
    }

    @media only screen and (max-width: 600px) {
      // [theme.breakpoints.between("xs", "sm")]: { 
      padding-top: 20px;
    }

    & .breadcrumb {
      font-weight: bold;
      font-size: 15px;
      margin-top: 15px;
      margin-bottom: 45px;
    }

    & .itemInfoImageArea {
      width: 100%;
      margin-bottom: 44px;

      & .itemInfoImageCarousel {}
    }

    & .itemInfoImageArea:after {
      border: 10px solid green;


      display: block;
      padding-bottom: 100%;
    }

    & .itemInfoImage {
      max-height: 500px;
      height: 100%;
      width: 100%;
    }

    & .itemInfoDescArea {
      padding: 115px 0px 40px 40px;

      &>.itemDesignerText {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 1rem;
        color: #555555;
      }

      &>.itemDetailTitle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        color: #000000;

        &>.itemDetailTitleText {
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.26;
        }
      }

      & .itemDetailDescText {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
      }

      @media only screen and (max-width: 600px) {
        // [theme.breakpoints.down("sm")]: {
        padding: 0;
      }

      & .strikeLine {
        @media only screen and (max-width: 600px) {
          // [theme.breakpoints.down("sm")]: {
          left: 15px;
          right: 15px;
        }
      }

      & .qtyCount {
        display: flex;
        padding-left: 20px;
        justify-content: space-between;
        max-width: 300px;
        align-items: center;
        margin: 10px 0;

        @media only screen and (max-width: 600px) {
          // [theme.breakpoints.down("sm")]: {
          max-width: unset;
        }
      }

      & .addCartBtn {
        @media only screen and (max-width: 600px) {
          // [theme.breakpoints.down("sm")]: {
          max-width: unset;
        }
      }
    }

    & .itemInfoDetailArea {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      padding-bottom: 5rem;
      min-height: 30rem;

      & .itemInfoDetailAreaInside {
        @media only screen and (max-width: 600px) {
          // [theme.breakpoints.down("sm")]: { 
          width: 100%;
        }

        @media only screen and (min-width: 600px) {
          // [theme.breakpoints.up("sm")]: { 
          width: 70%;
        }
      }

      & .itemInfoTab {
        display: flex;
        justify-content: space-around;

        @media only screen and (max-width: 600px) {
          // [theme.breakpoints.down("sm")]: {
          justify-content: space-between;
        }

        margin-bottom: 20px;

        & .tabbtn {
          font-size: 16px;
          cursor: pointer;
        }

        & .selected {
          font-weight: bold;
          text-decoration: underline;
        }
      }

      & .content {
        padding: 20px;
        display: flex;

        & div.technicalDetails {
          margin-left: auto;
          margin-right: auto;

          & .row {
            padding: 10px 0;
            max-width: 500px;
            display: flex;
            flex-wrap: wrap;

            & .key {
              width: 200px;
              font-weight: bold;
              flex: 0 0 200px;
            }

            & .value {
              flex: 1 1 200px;
            }
          }
        }
      }
    }
  }
}



// ItemInfoM
.itemInfoM {
  & .navBackButton {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: calcPx(186),
    // height: calcPx(186),
    // fontSize: calcPx(80),
    width: 16.4893617vw;
    height: 16.4893617vw;
    font-size: 7.092198582vw;
  }

  & .countOptionDiv {
    // height: calcPx(158),
    // margin: `auto ${calcPx(80)}`,
    height: 14.0070922vw;
    margin: auto 7.092198582vw;
    border-top: 2px solid #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// ItemCardDummy
.itemCardDummy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  & .titleBox {
    margin-top: 24px;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    // & .brand {}
    & .title {
      margin-top: 10px;
    }
  }

  & .subTitleBox {
    padding-top: 4px;
    margin-bottom: 20px;
    height: 24px;
  }

  & .itemCardBottomBox {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    & .MuiButton-root {
      font-weight: bold;
      font-size: 15px;
    }
  }
}

// ItemListTopMenu
.itemListTopMenu {

  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: center;

  & .mTopCategoryBC {
    width: 95%;

    @media only screen and (max-width: 600px) {

      // [theme.breakpoints.down("sm")]: {
      width: 100%;
    }

    & .breadcrumb2 {
      @media only screen and (max-width: 600px) {
        // [theme.breakpoints.down("sm")]: {
        padding-top: 0px;
        padding-left: 20px;
        font-size: 16px;
      }
    }

    & .subnodes {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 25px 0 30px 0;

      @media only screen and (max-width: 600px) {
        // [theme.breakpoints.down("sm")]: {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        font-size: 0.7rem;
      }

      & span.subNode {
        padding: 20px;

        @media only screen and (max-width: 600px) {
          // [theme.breakpoints.down("sm")]: {
          padding: 10px;
        }

        font-weight: bold;
        cursor: pointer;
        margin: 0px 5px;
        white-space: nowrap;
        text-decoration: underline;

        &.selectedSubNode {
          color: #e2c62f;
          font-weight: bold;
          text-decoration: underline;
        }

        &.pinned {
          color: #e2c62f;
        }
      }
    }
  }
}


// LoginBox
.loginBox {
  & .titleArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;

    & .desc {
      font-size: 16px;
    }
  }

  & .loginFields {
    width: 320px;
    height: 48px;
    margin: 10px 0px;

    & fieldset {
      border: solid 2px rgba(0, 0, 0, 0.05);
      border-radius: 0;
    }
  }

  & .loginButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    margin: 10px 0px;

    & button {
      height: 48px;
      font-size: 16px;
    }
  }
}




// LoginView
.loginView {
  min-height: 440px;

  & .loginBoxDiv {
    display: flex;
    justify-content: center;
    padding: 100px 0px;
  }
}

// LoginViewM
.loginViewM {
  min-height: 440px;

  & .loginBoxDiv {
    display: flex;
    justify-content: center;
    padding: 100px 0px;
  }

  .loginBoxBody {

    // fontFamily: 'Times', 'Nanum Myeongjo';
    & .titleArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 4rem;

      & .desc {
        font-size: 16px;
      }
    }

    & .loginFields {
      width: 320px;
      height: 48px;
      margin: 10px 0px;

      & fieldset {
        border: solid 2px rgba(0, 0, 0, 0.05);
        border-radius: 0;
      }
    }

    & .loginButtons {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 320px;
      margin: 10px 0px;

      & button {
        height: 48px;
        font-size: 16px;
      }
    }
  }
}

// MainView
.mainView {
  & a {
    color: unset;
    text-decoration: none;
  }
}

// MainViewM
.mainViewM {
  &>.body {

    padding-top: 6.64893617vw;
    padding-left: 3.546099291vw;
    padding-right: 3.546099291vw;

    &>.showRoomArea {
      background-color: #ffd508;

      font-size: 5.496453901vw;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 12.41134752vw;
      margin-top: 7.978723404vw;
    }

    &>.menuPanelArea {

      margin-top: 4.432624113vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &>.menuPanel {
        // width: `${px500}px`,
        // height: `${px500}px`,
        // marginTop: `${px40}px`,
        width: 44.32624113vw;
        height: 44.32624113vw;
        margin-top: 3.546099291vw;
      }
    }
  }

  & .vintageArea {
    &.disable {
      & .background {
        filter: blur(1px);
      }

      & .buttonArea {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    position: relative;

    height: 33.68794326vw;
    margin-top: 7.978723404vw;

    & .background {
      width: 100%;
      height: 100%;

      // backgroundImage: `url(/static/funimg6.jpg)`,
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .buttonArea {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      & .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 69.76950355vw;
        height: 7.80141844vw;
        color: white;
        border: 1px solid white;
      }
    }
  }
}


// MyPageView
.myPageView {
  height: 100%;

  & .Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;

    &.mobile {
      width: 100%;
      height: 100%;
    }

    & .breadCrumbs {
      position: relative;
      top: 15px;
      left: 2rem;
    }

    & .myPageContent {
      min-height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;

      & table {
        border-collapse: collapse;
        border: 1px solid lightgray;
        width: 280px;
        height: 280px;

        & td {
          border: 1px solid lightgray;
          width: 140px;
          height: 140px;
          // fontFamily: Times;
        }
      }

      & .myPageContentItem {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;

        & .titlekr {
          font-size: 11px;
        }
      }

      & .minibar {
        width: 50px;
        height: 2px;
        background: gray;
      }
    }
  }
}

// CartDiv
.cartDiv {
  &.mo {
    display: flex;
    padding: 10px;

    & .cartImage {
      width: 120px;
      height: 120px;
    }

    & .cartInfo {
      margin-left: 1rem;
    }

    & .cartInfo1 {
      font-size: 16px;
    }

    & .cartInfo2 {
      font-size: 14px;
    }

    & .cartInfo3 {
      font-size: 12px;
    }

    & .cartInfo4 {
      font-size: 12px;
    }

    & .cartInfo5 {
      font-size: 12px;
    }
  }

  &.pc {

    display: flex;
    padding: 10px;

    & .cartImage {
      width: 120px;
      height: 120px;
    }

    & .cartInfo {
      margin-left: 1rem;
    }

    & .cartInfo1 {
      font-size: 20px;
    }

    & .cartInfo2 {
      font-size: 18px;
    }

    & .cartInfo3 {
      font-size: 14px;
    }

    & .cartInfo4 {
      font-size: 14px;
    }

    & .cartInfo5 {
      font-size: 14px;
    }
  }
}


/* Text */
/* .adsc_title_text {
  font-family: "AppleSDGothicNeo";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #343538;
}

.adsc_subtitle_text {
  font-family: "AppleSDGothicNeo";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #737680;
}

.adsc_desc_text {
  font-family: "AppleSDGothicNeo";
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #343538;
} */

/* Commons */

/* .spinner {
  color: #6798e5;
  animation-duration: 550ms
}

.spinner.gray {
  color: gray;
  animation-duration: 550ms
}

/* Simple Table */
/* table.adsc_simpletable {
  border-collapse: collapse;


}

table.adsc_simpletable>thead>th {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  line-height: 40px;
}

table.adsc_simpletable>tbody>tr>td {
  border-bottom: 1px solid #efefef;
  padding-top: 24px;
  padding-bottom: 24px;
} */

/* Simple Form */
/* table.adsc_simpleform {}

table.adsc_simpleform tbody tr td.label {
  padding-bottom: 18px;
  padding-left: 18px;
  padding-right: 18px;
}

table.adsc_simpleform tbody tr td.addon {
  padding-bottom: 2px;
  display: flex;
  white-space: nowrap;
}


img.adsc_img_l {}

img.adsc_img_m {
  width: 64px;
  height: 64px;
}

img.adsc_img_s {
  width: 32px;
  height: 32px;
} */